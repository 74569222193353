import React, { useState, useEffect } from "react";
import "../styles/App.css";
import products from "../scripts/products.json";
import Filter from "./Filter";
import urban_header from "../assets/urban_header.webp";
import { useLocation, Link, withRouter } from "react-router-dom";
import product from "../assets/product.webp";
import icon_checkbox_outline from "../assets/icon_checkbox_outline.svg";
import clean_formulation_pink from "../assets/clean_formulation_pink.svg";
import vegan_pink from "../assets/vegan_pink.svg";

import cruelty_free_pink from "../assets/cruelty_free_pink.svg";

import derma_pink from "../assets/derma_pink.svg";
import natural_pink from "../assets/natural_pink.svg";
import alcohol_pink from "../assets/alcohol_pink.svg";
import step1 from "../assets/step1.webp";
import step2 from "../assets/step2.webp";
import step3 from "../assets/step3.webp";
import step4 from "../assets/step4.webp";
import related from "../assets/related.webp";
import ReactImageMagnify from "react-image-magnify";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import ShopifyButton from "./ShopifyButton";

function ElementDetail(props) {
  /* console.log(props); */
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchElements();
    /* console.log({ products }); */
  }, []);

  const [elements, setElements] = useState([]);

  //Get data from json file
  const fetchElements = async () => {
    const data = await fetch({ products });
    const elements = await data.json();
    setElements(elements.results);
    /* console.log("ejemplo", elements); */
  };

  /*   const showFilteredResults = (filters)=>{
  getProduct
}
*/
  let url = window.location.href;
  let urlId = url.split("s/")[1];

  

  return (
    <React.Fragment>
      {products.map((product) => {
        if (urlId == product.id) {
          var settings = {
            dots: false,
            infinite: true,
            autoplay: false,
            speed: 0,
            /* verticalSwiping: true, */
            slidesToShow: 1,
            /* autoplaySpeed: 3000, */
            arrows: true,
            vertical: false,
            slidesToScroll: 1,
          };

          return (
            <div key={product.id}>
              <img
                className="urban_header"
                src={urban_header}
                alt="urban secrets header"
              />

              <section className="products flex-between">
                <div className="product_images">

                <Slider
                    className="product_detailed thumb_list"
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    slidesToShow={10}
                    slidesToScroll={1}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={true}
                    verticalSwiping={true}
                    infinite={true}
                    autoplay={false}
                   /*  autoplaySpeed={3000} */
                   vertical={true}
                    dots={false}
                  >
                    <div className="product_detailed">
                      <img
                        src={product.image_url1}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url2}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url3}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url4}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url5}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url6}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url7}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url8}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url9}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url10}
                        alt={product.id}
                      />
                    </div>
                  </Slider>
                  
                  <Slider
                    className="product_detailed"
                    {...settings}
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                  >
                    <div className="product_detailed">
                      <img
                        src={product.image_url1}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url2}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url3}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url4}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url5}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url6}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url7}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url8}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url9}
                        alt={product.id}
                      />
                    </div>
                    <div className="product_detailed">
                      <img
                        src={product.image_url10}
                        alt={product.id}
                      />
                    </div>
                  </Slider>

                  
                </div>
                {/*  <div className="product_detailed_mobile">
                <img src={product.image_url} alt="product urban secrets"/>
            </div> */}

                <article>
                  <h1 className="subtitle2 text-left">{product.title}</h1>
                  <h4 className="product_size">{product.size}</h4>
                  <h2 className="price">${product.price}</h2>
                  <p className="text-left">{product.description}</p>
                  {/*  <ul className="flex-start pink">
                    <li className="category"><img src={icon_checkbox_outline} alt="checkbox_icon"/> MORNING</li>
                    <li className="category"><img src={icon_checkbox_outline} alt=""/> MOISTURIZE</li>
                </ul> */}

                  <ul className="flex-center pink_icons">
                    <li>
                      <img src={clean_formulation_pink} alt="" />
                    </li>
                    <li>
                      <img src={vegan_pink} alt="" />
                    </li>
                    <li>
                      <img src={cruelty_free_pink} alt="" />
                    </li>

                    <li>
                      <img src={derma_pink} alt="" />
                    </li>
                    <li>
                      <img src={natural_pink} alt="" />
                    </li>
                    <li>
                      <img src={alcohol_pink} alt="" />
                    </li>
                  </ul>
                  <div className="buttons_buy">
                    <ShopifyButton idShopify1={product.shopifyId_1} idShopify2={product.shopifyId_2}/>
                  <a href={product.link} target="_blank">
                    <button className="call_to_action center_button">
                      BUY ON AMAZON
                    </button>
                  </a>

                  </div>
                </article>
              </section>

              <hr className="center_line2" />
              <section className="description">
                <article>
                  <h2 className="subtitle2 subtitleProducts text-center">
                    HOW TO USE
                  </h2>
                  <p>{product.how_to_use}</p>
                  <br />
                  <br />

                  <h2 className="subtitle2 subtitleProducts text-center">
                    INGREDIENTS
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: product.Ingredients }}
                  ></p>

                  <br />
                  <br />

                  <h2 className="subtitle2 subtitleProducts text-center">
                    CAUTION
                  </h2>
                  <p>{product.caution}</p>
                  <br />
                  <br />
                </article>
              </section>

              <section className="stand2">
                <h3 className="subtitle2 text-center">COMPLETE YOUR ROUTINE</h3>
                <br />
                <article className="description flex-between">
                  <a
                    className="step"
                    href="https://theurbansecrets.com/our-products/nourishing-witch-hazel-makeup-melt-gel-cleanser"
                  >
                    <img src={step1} alt="step 1" />
                    <h4 className="subtitle3">STEP 1</h4>
                    <h5 className="subtitle4">Clean</h5>
                  </a>

                  <a
                    className="step"
                    href="https://theurbansecrets.com/our-products/revitalizing-rose-water-re-hydrating-toner"
                  >
                    <img src={step2} alt="step 2" />
                    <h4 className="subtitle3">STEP 2</h4>
                    <h5 className="subtitle4">Tone</h5>
                  </a>

                  <a
                    className="step"
                    href="https://theurbansecrets.com/our-products/brightening-age-defying-vitamin-c-supercharged-serum"
                  >
                    <img src={step3} alt="step 3" />
                    <h4 className="subtitle3">STEP 3</h4>
                    <h5 className="subtitle4">Prevent</h5>
                  </a>

                  <a
                    className="step"
                    href="https://theurbansecrets.com/our-products/tightening-collagen-peptides-oil-free-lightweight-moisturizer"
                  >
                    <img src={step4} alt="step 4" />
                    <h4 className="subtitle3">STEP 4</h4>
                    <h5 className="subtitle4">Moisturize</h5>
                  </a>
                </article>
              </section>

              <section className="stand_empty">
                <h3 className="subtitle2 text-center">RELATED PRODUCTS</h3>
                <br />
                <article className="description flex-between">
                  <a href={product.related1_link}>
                    <div className="step">
                      <div className="step_img">
                        <img src={product.related1_img} alt="step 3" />
                      </div>
                      <h5 className="subtitle4 text-center">
                        {product.related1_name}
                      </h5>
                      <h6 className="size_related">
                      {product.related1_size}
                      </h6>
                      {/* <h4 className="price2">14.99</h4> */}
                    </div>
                  </a>

                  <a href={product.related2_link}>
                    <div className="step">
                      <div className="step_img">
                        <img src={product.related2_img} alt="step 3" />
                      </div>
                      <h5 className="subtitle4 text-center">
                        {product.related2_name}
                      </h5>
                      <h6 className="size_related">
                      {product.related2_size}
                      </h6>
                      {/* <h4 className="price2">14.99</h4> */}
                    </div>
                  </a>

                  <a href={product.related3_link}>
                    <div className="step">
                      <div className="step_img">
                        <img src={product.related3_img} alt="step 3" />
                      </div>
                      <h5 className="subtitle4 text-center">
                        {product.related3_name}
                      </h5>
                      <h6 className="size_related">
                      {product.related3_size}
                      </h6>
                      {/* <h4 className="price2">14.99</h4> */}
                    </div>
                  </a>
                </article>
              </section>
            </div>
          );
        }
      })}
    </React.Fragment>
  );
}

export default withRouter(ElementDetail);
