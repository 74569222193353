import React from 'react';
import './styles/aos.css';
import './styles/App.css';
import Head from './components/Head';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Give_back from './components/Give_back';
import Our_story from './components/Our_story';
import Our_products from'./components/Our_products';
import Contact_us from './components/Contact_us';
import ElementDetail from './components/ElementDetail';
import NotFoundPage from './components/NotFoundPage';

import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom' 




function App() {
 
  return (
    <Router>
      <div>
        <Header />
        <Switch>
        
          <Route path='/' exact component={Home} />
          <Route path='/product/all-products/facial/oil-free-lightweight-moisturizer/' exact component={Our_products} />
          <Route path='/product/all-products/facial/glow-boosting-serum-cream-antioxidant-6-in-1-complex/' exact component={Our_products} />
          <Route path='/product/all-products/facial/oil-free-lightweight-moisturizer/' exact component={Our_products} />
          <Route path='/product/all-products/facial/supercharged-serum/' exact component={Our_products} />
          <Route path='/product/all-products/facial/toners/illuminating-toner/' exact component={Our_products} />
          <Route path='/product/all-products/facial/toners/rehydrating-toner/' exact component={Our_products} />
          <Route path='/product/all-products/facial/toners/illuminating-toner/' exact component={Our_products} />
          <Route path='/product/all-products/facial/toners/rehydrating-toner/' exact component={Our_products} />
          <Route path='/product/all-products/facial/cleansers/clay-to-foam-cleanser/' exact component={Our_products} />
          <Route path='/product/all-products/facial/cleansers/foam-cleanser/' exact component={Our_products} />
          <Route path='/product-category/facial/serums/' exact component={Our_products} />
          <Route path='/product/all-products/facial/clay-to-foam-cleanser/' exact component={Our_products} />
          <Route path='/product/all-products/facial/cleansers/foam-cleanser-2/' exact component={Our_products} />
          <Route path='/product/all-products/facial/auto-draft/' exact component={Our_products} />
          <Route path='/product/all-products/facial/herbal-calming/' exact component={Our_products} />
          <Route path='/product/all-products/sheet-masks/herbal-sheet-masks/rose-and-jasmine' exact component={Our_products} />

        

          <Route path='/our-story' component={Our_story} />
          <Route path='/give-back' component={Give_back} />
          <Route path='/contact-us' component={Contact_us} />
          <Route path='/our-products' exact component={Our_products} />
          <Route path='/our-products/:id' exact component={ElementDetail} />
          <Route path="*" component={NotFoundPage} status={404}/>
          <Redirect to="*" status={301} />
        </Switch>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
