import React, {useEffect } from 'react';
import '../styles/App.css';
import urban_header from '../assets/urban_header.webp'
import conscious_brand from '../assets/conscious_brand.webp'
import carefully_crafted from '../assets/carefully-crafted.webp'
import children from '../assets/children.webp'
import carefully_crafted3 from '../assets/urban_aloe.webp'
import artistic from '../assets/artistry_urban.webp'
import brush from '../assets/brush.webp'



function Our_story() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <React.Fragment>
      <img className="urban_header" src={urban_header} alt=""/>
      <section className="highlight_container flex-between align-start">
          <article className="section_right change_order">

        <div className="subtitle_container">    
          <h3 className="subtitle text-left">
              WHO WE ARE
              </h3>
              <img className="brush img_left w30" src={brush} alt="brush line" />
        </div>

              
              <p className="text-left">Urban Secrets was born from our passion to formulate skincare products with honestly good ingredients and to actually deliver on what many other brands have promised.</p> <br/>
              <p className="text-left">
                  After over 20 years of selling and developing brands, our founder felt it was his calling to correct everything that was wrong and corporate driven to bring some justice to consumers.
              </p><br />
              <p className="text-left">
              Our team set a goal to bring to market products that would not cut corners, would not look at profit before quality, would not substitute ingredients for lower cost alternatives, would not hide behind fancy names or senseless trademarked slogans and would not be harmful in the long run.
              </p>
            
              
          </article>
          <img src={conscious_brand} alt="urban secrets girl"/>
      </section>


      <section className="highlight_container flex-between align-end">
          <article className="section_left">

          <div className="subtitle_container">    
              <h3 className="subtitle text-right">
              OUR DIFFERENCE
              </h3>
              <img className="brush img_right" src={brush} alt="brush line" />
        </div>

              <p className="text-right">Our mission is simple; give people the best innovative formulations with the highest percentage of effective, natural ingredients at a nominal cost. Our base ingredient is Aloe Juice for its many benefits instead of just water, which makes our formulations unique. We also use all vegan ingredients and have a clean formulation which keeps out over 2000 toxic cosmetics ingredients, including skin-dehydrating alcohol. We kept everything good in and took everything bad out, just like if you were to make it in the kitchen yourself.</p> <br/>
              <p className="text-right">
              Conscious Beauty is now here.
              </p>
             
          </article>
          <img src={carefully_crafted3} alt="urban secrets girl"/>
      </section>






      <section className="grey">
          <h4 className="subtitle2 text-center text-white">WE CARE ABOUT PROTECTING SKIN, & OUR PLANET</h4>
          <h3 className="subtitle text-center subtitle-stand">OUR PLANET IS IN TROUBLE</h3>
          <p className="text-center text-white stand_text">Being able to recycle helps our customers be part of sustainable living<br/>
           </p>
          

      </section>


      <section className="highlight_container flex-between align-start">
          <article className="section_right change_order">

          <div className="subtitle_container">
              <h3 className="subtitle text-left">
                  artistry
              </h3>
              <img className="brush img_left w15" src={brush} alt="brush line" />
            </div>

              <p className="text-left">The evolution of each product from ideation to formulation to meet its purpose to testing over and over again is passionately done over a lengthy period of time like creating a fine piece of art.</p> <br/>
              
              <p className="text-left">The paint brush strokes on our packaging depicts the artistry it takes in the making of our products and the vibrant colours depicts the bursting energy and wholeheartedness our teams put in.</p>
            
            
          </article>
          <img src={artistic} alt="urban secrets girl"/>
      </section>

      <section className="highlight_container flex-between align-end">
          <article className="section_left">

          <div className="subtitle_container">
              <h3 className="subtitle text-right">
                  giving back
              </h3>
              <img className="brush img_right" src={brush} alt="brush line" />
            </div>

              <p className="text-right">In addition to creating the highest quality products, we wanted to give back and help children with disadvantaged backgrounds.</p> <br/>
              <p className="text-right">
                  There are so many in the world living in unsanitary conditions and prone to diseases. To help combat this, we donate 1 bar of soap for each product sold.
              </p>
              
          </article>
          <img src={children} alt="urban secrets girl"/>
      </section>

      <section className="stand">
      <div className="subtitle_container">
        </div>
          <h4 className="subtitle2 text-center">We did everything for a reason with every step
              carefully crafted. <br/></h4>
              <h4 className="subtitle2 text-center">Our products are made for our family and yours!</h4>
      </section>
    </React.Fragment>
  );
}

export default Our_story;
