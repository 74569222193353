/* import React from 'react';
import '../styles/App.css'; */
import urban_header from '../assets/urban_header.webp'
import React, { useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import contact from '../assets/contact.webp'



export const Contact_us = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

  emailjs.sendForm('service_zdvoaq6','template_vxm9nbj', form.current, 'user_X7CYA0oVl0jjMRA26aoNN')
  .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });
  form.current.reset()
  alert("Thank you for your email! We will contact you as soon as possible")
}


  return (
    <React.Fragment>
      <img className="urban_header" src={urban_header} alt="urban secrets"/>


  

      <section className="highlight_container flex-between padding_bottom">

        <img src={contact} alt="contact urban secrets" />
          <article className="contact section_right change_order"> 
         
              <p>We’d love to hear from you!</p> <br />
              <p>Email us with any questions or inquires, we will be in touch with you <br /> as soon as possible.</p>

              <form ref={form} className="contact_form" onSubmit={sendEmail}>
                <input required type="email" placeholder="Email Address" name="from_email" /><br />
                <input required type="phone" placeholder="Telephone" name="from_phone"/><br />
                <input required type="Message" placeholder="Message" name="message"/><br />
                <input type="submit" value="SEND" />
              </form>

          </article>
      </section>

        

    </React.Fragment>
  );
}

export default Contact_us;
