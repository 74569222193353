import React, { useState, useEffect } from "react";
import "../styles/App.css";
import products from "../scripts/products.json";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import Filter from "./Filter";
import urban_header from "../assets/urban_header.webp";

function Our_products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [Filters, setFilters] = useState({
    categories: [],
  });

  const [selectedCategory, setSelectedCategory] = useState(["All Products"]);

  useEffect(() => {
    fetchElements();
    console.log({ products });
  }, []);

  const [elements, setElements] = useState([]);

  //Get data from json file
  const fetchElements = async () => {
    const data = await fetch({ products });
    const elements = await data.json();
    setElements(elements.results);
    console.log("ejemplo", elements);
  };

  /*   const showFilteredResults = (filters)=>{
    getProduct
  }
 */

  const handleFilters = (filters, category) => {
    const mapping = {
      "1c": "All Products",
      "2c": "Cleansers",
      "3c": "Toners",
      "4c": "Sheet Masks",
      "5c": "Tightening Masks",
    };

    const newFilters = { ...Filters };
    newFilters[category] = filters;

    const filteredCategories = [];
    for (const filterId of filters) {
      filteredCategories.push(mapping[filterId]);
    }

    /* showFilteredResults(newFilters) */
    setSelectedCategory(filteredCategories);

    setFilters(newFilters);
  };

  return (
    <React.Fragment>
      <img
        className="urban_header"
        src={urban_header}
        alt="urban secrets header"
      />
      <section className="products flex-between">
        {/*  <Filter
        handleFilters={handleFilters}
     /> */}
        <article className="product_list flex-between">
          {/* Render products */}
          {products.map((product) => {
            console.log(selectedCategory);
            console.log(product.product_category);
            /*         if (selectedCategory.includes(product.product_category)) { */

            return (
              <div className="product" key={product.id}>
                <Link
                  className="container_thumbnail"
                  to={{
                    pathname: `/our-products/${product.id}`,
                    state: { product: product },
                  }}
                >
                  <img
                    className="product_thumbnail"
                    src={product.image_url}
                    alt="product urban secrets"
                  />
                  <h4 className="product_title">{product.title}</h4>
                  <h4 className="product_size_list">{product.size}</h4>
                  <h5 className="product_price main_color_text">
                    ${product.price}
                  </h5>
                </Link>
              </div>
            );
            /*    } */
            return "";
          })}
        </article>
      </section>
    </React.Fragment>
  );
}

export default Our_products;
