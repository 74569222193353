import React, {useState, useEffect} from 'react'
import '../styles/App.css';
import urban_header from '../assets/urban_header.webp'
import conscious_brand from '../assets/conscious_brand.webp'
import carefully_crafted from '../assets/carefully-crafted.webp'
import give_back from '../assets/give_back.webp'
import you_buy_we_donate_big from '../assets/you_buy_we_donate.svg'
import you_buy_we_donate from '../assets/you-buy-we-donate.webp'
import urban_secrets_logo_black from '../assets/urban_secrets_logo_black.svg'
import eco_soap_bank from '../assets/eco-soap-bank.webp'
import awarded_by_cnn_heroes from '../assets/awarded-by-cnn-heroes.webp'


import donation_1 from '../assets/donation-1.webp'
import donation_2 from '../assets/donation-2.webp'
import donation_3 from '../assets/donation-3.webp'
import donation_icon from '../assets/donation.svg'
import brush from '../assets/brush.webp'



function Give_back() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <img className="urban_header" src={urban_header} alt=""/>


  

      <section className="highlight_container flex-between">
          <article className="section_right change_order"> 
          <img src={you_buy_we_donate_big} className="we_donate"/>
          <br />

        <div className="subtitle_container">
            <h3 className="subtitle">
                  THEY RECEIVE HOPE
            </h3>
            <img className="brush w70" src={brush} alt="brush line" />
        </div>
              <p>In Collaboration with Eco Soap Bank, and with your support, we are helping to stop the spread of preventable diseases by donating one soap bar with every product purchased and giving hope to vulnerable children in need. We are all in this together!</p>
              <ul className="icons group4 flex-around">
              <img src={awarded_by_cnn_heroes} alt="cruelty_free"/>
              <img src={eco_soap_bank} alt="eco soap"/>
                <img src={you_buy_we_donate} alt="cruelty_free"/>
                
               
                
            </ul>
          </article>
          <img src={give_back} alt="urban secrets girl"/>
      </section>

        <hr className="center_line"/>
      <section className="paragraph-center text-center">
          
          <p className="text-center">To help stop the spread of preventable diseases we developed a plan to help improve the hygiene of children by providing soap, as well as education about proper hand washing techniques. Every time someone purchases one of our products, we donate a bar of soap to a child in need. If you feel moved to get involved and join us on this lifesaving journey, please contact us or Eco-Soap Bank. Thank you!</p>
          

      </section>
      
      <section className="content-section">

      <div className="subtitle_container">
          <h3 className="subtitle text-center">
                YOUR PURCHASE
          </h3>
          

          <h3 className="subtitle  text-center">
                HELPS IN
            </h3>
            <img className="brush w15" src={brush} alt="brush line" />
        </div>

          
          <section className="prevention_images flex-center">

            <div className="img_donation">
                <h3 className="text_img_donation">DISEASE PREVENTION</h3>
                <img src={donation_1} alt="donation"/>
            </div>  
            <div className="img_donation">
                <h3 className="text_img_donation">HYGIENE EDUCATION</h3>
                <img src={donation_2} alt="donation"/>
            </div>   
            <div className="img_donation">
                <h3 className="text_img_donation">HEALTHIER SCHOOLS</h3>
                <img src={donation_3} alt="donation"/>
            </div>  
            

          </section>
      </section>

      <h4 className="subtitle2 text-center pink-text">
      1 PURCHASE = 1 BAR OF SOAP = A HEALTHIER PLANET FOR ALL
       </h4>

       <section className="paragraph-center text-center">
          <p className="text-center">We’re in this together! let’s make a difference, 1 child and 1 bar of soap at a time!</p>
      </section>

      <img className="donation" src={donation_icon} alt="donation" />

    </React.Fragment>
  );
}

export default Give_back;
