import '../styles/App.css';
import '../styles/aos.css';
import social_responsability_line_icon from '../assets/social_responsability_line_icon.webp'
import clean_line_icon from '../assets/clean_line_icon.webp'
import alcohol_free_line_icon from '../assets/alcohol_free_line_icon.webp'
import cruelty_free_line_icon from '../assets/cruelty_free_line_icon.webp'
import dermatologist_tested_line_icon from '../assets/dermatologist_tested_line_icon.webp'
import sustainability_line_icon from '../assets/sustainability_line_icon.webp'
import vegan_line_icon from '../assets/vegan_line_icon.webp'
import natural_ingredients_line_icon from '../assets/natural_ingredients_line_icon.webp'
import urban_secrets_logo from '../assets/urban_secrets_logo.webp'
import tik_tok_icon from '../assets/tik_tok_icon.svg'
import facebook_icon from '../assets/facebook_icon.svg'
import instagram_icon from '../assets/instagram_icon.svg'
import {Helmet} from "react-helmet";
import AOS from "aos";
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'


function Home() {
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return (  
        <footer>
        <ul className="flex-center group3">
            <img src={social_responsability_line_icon} alt="alcohol free"/>
            <img src={clean_line_icon} alt="clean formulation"/>
            <img src={alcohol_free_line_icon} alt="cruelty_free"/>
            <img src={cruelty_free_line_icon} alt="vegan"/>
            <img src={dermatologist_tested_line_icon} alt="dermatologist tested"/>
            <img src={sustainability_line_icon} alt="dermatologist tested"/>
            <img src={vegan_line_icon} alt="dermatologist tested"/>
            <img src={natural_ingredients_line_icon} alt="dermatologist tested"/>
        </ul>

        <article className="flex-center footer_contact">
           <Link to="/"> <img className="logo logo_footer" src={urban_secrets_logo} alt="urban secrets logo"/></Link>
            <div className="social_footer">
                 <h4 className="text-center follow">#theurbansecrets</h4>
                <ul className="social_media flex-center">
                    <li><a href="https://www.tiktok.com/@urbansecretsofficial" target="_blank"><img src={tik_tok_icon} alt="tik tok"/></a></li>
                    <li><a href="https://www.facebook.com/urbansecretsofficial/" target="_blank"><img src={facebook_icon} alt="facebook"/></a></li>
                    <li><a href="https://www.instagram.com/urbansecretsofficial/" target="_blank"><img src={instagram_icon} alt="instagram"/></a></li> 
                </ul>
            </div>
               
        </article>
       
        <p className="copyright text-center">All Rights Reserved | Urban Secrets, 800 5th Ave, Suite 101 Seattle, WA 98104</p>
        <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
    <script>
        AOS.init();
    </script>
    <Helmet>
        <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
        <script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js" defer></script>
        
    </Helmet>    
    </footer>
    );
}

export default Home;