import React, { useState, useEffect } from 'react';
import alcohol_free from '../assets/alcohol-free-img.webp'
import social_awarness_icons from '../assets/social_awarness_icons.webp'
import cleansers from '../assets/cleansers.webp'
import natural_ingredients_icons from '../assets/natural_ingredients.webp'
import natural_ingredients_icons1 from '../assets/natural-ingredients-icons1.svg'
import cleansers_mobile from '../assets/cleansers-mobile.webp'
import serums from '../assets/serums.webp'
import serums_mobile from '../assets/serums_mobile.webp'
import top_banner_background from '../assets/top-banner-background.webp'
import top_banner_background_mobile from '../assets/top-banner-background-mobile.webp'
import carefully_crafted from '../assets/carefully-crafted.webp'
import alcohol_free_icon from '../assets/alcohol-free-icon.svg'
import clean_formulation_icon from '../assets/clean_formulation_icon.svg'
import cruelty_free_icon from '../assets/cruelty_free_icon.svg'
import vegan_icon from '../assets/vegan_icon.svg'
import dermatologist_tested from '../assets/dermatologist-tested.svg'
import detail from '../assets/detail.webp'
import social_responsibility from '../assets/social-responsibility.webp'
import you_buy_we_donate from '../assets/you-buy-we-donate.webp'
import eco_soap_bank from '../assets/eco-soap-bank.webp'
import awarded_by_cnn_heroes from '../assets/awarded-by-cnn-heroes.webp'
import tea_cream from '../assets/tea_cream.webp'
import sustainability from '../assets/sustainability.webp'
import raising_awareness from '../assets/raising-awareness.webp'
import Carousel from 'react-elastic-carousel'
import tonners_carousel from '../assets/tonners_carousel.webp'
import tonners_carousel_mobile from '../assets/tonners_carousel_mobile.webp'
import brush from '../assets/brush.webp'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const articleStructuredData = {
        "@context": "https://schema.org",
        "@type": "Urban Secrets, skincare products with vegan ingredients and clean formulation",
        description: "Skincare products with honestly good ingredients",
        image: "https://theurbansecrets.com/static/media/cleansers-mobile.3f042553.webp",
        datePublished: new Date("2021-09-04T09:25:01.340Z").toISOString(),
        author: {
          "@type": "Organization",
          name: "Urban Secrets",
          url: "https://theurbansecrets.com",
        },
      };
  return (
    <main>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
    <section className="carousel">
    <Slider slidesToScroll={1} 
    dots={true}
    infinite={true}
    speed={500}
    autoplay={true}
    autoplaySpeed={5000}
    >

    <div className="img_container">

                <article className="banner_text">
                    <h4 className="subtitle2 black_bold">WE BRING PRODUCTS TO YOU
                    </h4>
                    <h2 className="mainTitle mainTitle-desktop">
                        THAT WOULD NOT <br/>
                        CUT CORNERS
                    </h2>

                    <h2 className="mainTitle mainTitle-mobile">
                    THAT WOULD NOT <br/>
                        CUT CORNERS
                    </h2>
                    
                    <div data-aos="fade-right" className="main_icons">
                        <img src={natural_ingredients_icons1} alt="natural ingredients"/>
                    </div>
                
                </article>

                <picture data-aos="fade-down" className="absolute">
                    <source media="(min-width:790px)" srcSet={cleansers} className="absolute" alt="urban Secrets, good ingredients and passion"/>
                    <img src={cleansers_mobile} alt="Flowers" className="absolute-mobile" alt="urban Secrets, good ingredients and passion"/>
                </picture>

                <picture>
                    <source media="(min-width:790px)" srcSet={top_banner_background}/>
                    <source media="(min-width:465px)" srcSet={top_banner_background_mobile}/>
                    <img src={top_banner_background_mobile} alt="Flowers"/>
                </picture>

    </div>


    {/* Second element */}

    <div className="img_container">

    <article className="banner_text">
                    <h4 className="subtitle2 black_bold">
                        WE FORMULATE WITH HONEST
                    </h4>
                    <h2 className="mainTitle mainTitle-desktop">
                        good ingredients <br/>
                        and passion
                    </h2>

                    <h2 className="mainTitle mainTitle-mobile">
                    good ingredients <br/>
                        and passion
                    </h2>
                    
                    <div data-aos="fade-right" className="main_icons icons_natural">
                        <img src={natural_ingredients_icons} alt="natural ingredients"/>
                    </div>
                
                </article>

                <picture data-aos="fade-down" className="absolute">
                    <source media="(min-width:790px)" srcSet={tonners_carousel} className="absolute" alt="urban Secrets, good ingredients and passion"/>
                    <img src={tonners_carousel_mobile} alt="Flowers" className="absolute-mobile" alt="urabn Secrets, good ingredients and passion"/>
                </picture>

                <picture>
                    <source media="(min-width:790px)" srcSet={top_banner_background}/>
                    <source media="(min-width:465px)" srcSet={top_banner_background_mobile}/>
                    <img src={top_banner_background_mobile} alt="Flowers"/>
                </picture>

    </div>

     {/* Third element */}

     <div className="img_container">

     <article className="banner_text">
                    <h4 className="subtitle2 black_bold">
                        FOR EVERY PRODUCT PURCHASED
                    </h4>
                    <h2 className="mainTitle mainTitle-desktop">
                        WE DONATE A BAR OF SOAP <br/>
                        TO A CHILD IN NEED
                    </h2>
                    <h5 className="p_main_image text-center">
                        with the support of our partner <br/>
                        ecosoapbank.Org
                    </h5>

                    <h2 className="mainTitle mainTitle-mobile">
                    WE DONATE A BAR OF SOAP <br/>
                        TO A CHILD
                    </h2>

                    
                    
                    <div data-aos="fade-right" className="main_icons">
                        <img src={social_awarness_icons} alt="natural ingredients"/>
                    </div>
                
                </article>

<picture data-aos="fade-down" className="absolute">
    <source media="(min-width:790px)" srcSet={serums} className="absolute" alt="urban Secrets, good ingredients and passion"/>
    <img src={serums_mobile} alt="Flowers" className="absolute-mobile" alt="urabn Secrets, good ingredients and passion"/>
</picture>

<picture>
    <source media="(min-width:790px)" srcSet={top_banner_background}/>
    <source media="(min-width:465px)" srcSet={top_banner_background_mobile}/>
    <img src={top_banner_background_mobile} alt="Flowers"/>
</picture>

</div>

</Slider>
            
            
            
       
    </section>
    

    <section className="highlight_container flex-between align-end">
        <article className="section_left">
            
        <div className="subtitle_container">
            <h2 className="subtitle">
                Carefully<br /> Crafted
            </h2>
            <img className="brush" src={brush} alt="brush line" />
        </div>
        <h1>{articleStructuredData.headline}</h1> <br />
            <p>{articleStructuredData.author.name} was born from our passion to formulate <br /> and to actually deliver on what many other brands have promised. {articleStructuredData.description}.</p>
             
            <br/>
            <p>
                After over 20 years of selling and developing brands, our founder felt it was his calling to correct everything that was wrong and corporate driven to bring some justice to consumers.
            </p>
             <Link to="/our-story" className="call_to_action center_button" >Our Story</Link>
        </article>
        <img className="urban_secrets_girl" src={carefully_crafted} alt="urban secrets girl"/>
    </section>


    <section className="highlight_container flex-between background_right">
        <article className="section_right change_order">

        <div className="subtitle_container">
            <h2 className="subtitle">
                Be kind <br />to your skin
            </h2>
            <img className="brush w35" src={brush} alt="brush line" />
        </div>
        
            <p>We use cruelty free, vegan ingredients & have clean formulations that keep out over 2,000 toxic ingredients, including skin-dehydrating alcohol.</p>
            
            <div className="be_kind">
                <img src={natural_ingredients_icons1} alt="natural ingredients"/>
            </div>
            
            
            
        </article>
        <img src={alcohol_free} alt="alcohol free"/>
    </section>


  


{/*     <section className="our_story">
        <div className="vimeo">
         <img src={urban_secrets_rose} alt="" />
         </div>
    </section> */}

    <section className="highlight_container flex-between background_left relative">
        <article className="section_left">

        <div className="subtitle_container">
            <h4 className="subtitle2 text-center">
                WE KEEP EVERYTHING
            </h4>
            <h2 className="subtitle">
                bad out!
            </h2>
            <img className="brush w35" src={brush} alt="brush line" />
        </div>
            <p>Aloe Vera Juice and not water is the base ingredient in all of our formulations.</p> <br/>
            <p>
            Our mission is simple; give people the best innovative formulations with high efficacy and make it affordable.
            </p>
        </article>
        <div className="cream_space">
        <div className="cream_container">
            <div className="cream" data-aos="fade-down">
                <img src={tea_cream} alt="tea cream" />
            </div>
            <div className="cream_detail" data-aos="fade-up">
                <img src={detail} alt="cream detail" />
            </div>
        </div></div>
        

    </section>


    <section className="highlight_container flex-between align-start">
        <article className="section_right change_order">

        <div className="subtitle_container">
            <h2 className="subtitle ">
            social responsibility
            </h2> 
            <img className="brush w70" src={brush} alt="brush line" />
        </div>


            <p> With your support, we are helping to stop the spread of preventable diseases by improving hygiene for every child in need. We donate a bar of soap for every product purchased with the support of our partner, ecosoapbank.Org. Your purchase has impact!</p> 
            <br/>

            <ul className="icons group2 flex-between wrap">
                <img className="cnn" src={awarded_by_cnn_heroes} alt="vegan"/>
                <img src={eco_soap_bank} alt="vegan"/>
            </ul>
            
            <p>We also care about protecting our planet. Being able to recycle helps our customers be part of sustainable living while we transition towards 100% recycled  packaging. </p>
            <ul className="icons group2 flex-between wrap">
            <Link to="/give-back" className="call_to_action center_button" >Give Back</Link>
            </ul>
            
        </article>
        <img src={raising_awareness} alt="raising awareness" />
    </section>

</main>
  );
}

export default Home;
