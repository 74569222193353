import { Link } from 'react-router-dom'
import urban_secrets_logo from '../assets/urban-secrets-logo.svg'
import React, {useEffect} from 'react'
import facebook_icon from '../assets/icon_awesome-facebook-f.webp'
import twitter_icon from '../assets/icon_awesome-twitter.webp'
import instagram_icon from '../assets/icon_awesome-instagram.webp'


function Header() {

  useEffect(() =>{
    hideMenu();
  },[])
  

  
  //Get data from json file
  const hideMenu = () =>{
    let links = document.querySelectorAll(".menu a")
    const menu_btn_responsive = document.querySelector("#menu-btn");  
    console.log(menu_btn_responsive);
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", ()=>{
        menu_btn_responsive.checked = false;
      })
      }
  }
  


  

  return (
  <header className="header">
   <Link to="/" className="logo"><img  src={urban_secrets_logo} alt="urban secrets logo"/></Link>
  <input className="menu-btn" type="checkbox" id="menu-btn" />
  <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
  <ul className="menu">
  <li> <Link to="/">Home </Link></li>
                <li><Link to="/our-story">Our Story</Link></li>
                <li><Link to="/our-products">Our Products</Link></li>
                <li><Link to="/give-back">Give Back</Link></li>
                <li><Link to="/contact-us">Contact Us</Link></li>
  </ul>
</header>
  );
}

export default Header;
