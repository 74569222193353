import React, { useState, useEffect } from 'react';
import alcohol_free from '../assets/alcohol-free-img.webp'
import social_awarness_icons from '../assets/social_awarness_icons.webp'
import cleansers from '../assets/cleansers.webp'
import natural_ingredients_icons from '../assets/natural_ingredients.webp'
import natural_ingredients_icons1 from '../assets/natural-ingredients-icons1.svg'
import cleansers_mobile from '../assets/cleansers-mobile.webp'
import serums from '../assets/serums.webp'
import serums_mobile from '../assets/serums_mobile.webp'
import top_banner_background from '../assets/top-banner-background.webp'
import top_banner_background_mobile from '../assets/top-banner-background-mobile.webp'
import carefully_crafted from '../assets/carefully-crafted.webp'
import alcohol_free_icon from '../assets/alcohol-free-icon.svg'
import clean_formulation_icon from '../assets/clean_formulation_icon.svg'
import cruelty_free_icon from '../assets/cruelty_free_icon.svg'
import vegan_icon from '../assets/vegan_icon.svg'
import dermatologist_tested from '../assets/dermatologist-tested.svg'
import detail from '../assets/detail.webp'
import social_responsibility from '../assets/social-responsibility.webp'
import you_buy_we_donate from '../assets/you-buy-we-donate.webp'
import eco_soap_bank from '../assets/eco-soap-bank.webp'
import awarded_by_cnn_heroes from '../assets/awarded-by-cnn-heroes.webp'
import tea_cream from '../assets/tea_cream.webp'
import sustainability from '../assets/sustainability.webp'
import raising_awareness from '../assets/raising-awareness.webp'
import Carousel from 'react-elastic-carousel'
import tonners_carousel from '../assets/tonners_carousel.webp'
import tonners_carousel_mobile from '../assets/tonners_carousel_mobile.webp'
import brush from '../assets/brush.webp'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



function NotFoundPage() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
  return (
    <main >
      
    <section className="carousel">
    <Slider slidesToScroll={1} 
    dots={true}
    infinite={true}
    speed={500}
    autoplay={false}
    autoplaySpeed={5000}
    >

    <div className="img_container">

                <article className="banner_text">
                    <h4 className="subtitle2 black_bold">WE COULD NOT FIND THIS PAGE
                    </h4>
                    <h2 className="mainTitle mainTitle-desktop">
                        ERROR 404 <br/>
                        PAGE NOT FOUND
                    </h2>

                    <h2 className="mainTitle mainTitle-mobile">
                    ERROR 404 <br/>
                        PAGE NOT FOUND
                    </h2>
                    
                    
                
                </article>

                <picture data-aos="fade-down" className="absolute">
                    <source media="(min-width:790px)" srcSet={cleansers} className="absolute" alt="urban Secrets, good ingredients and passion"/>
                    <img src={cleansers_mobile} alt="Flowers" className="absolute-mobile" alt="urabn Secrets, good ingredients and passion"/>
                </picture>

                <picture>
                    <source media="(min-width:790px)" srcSet={top_banner_background}/>
                    <source media="(min-width:465px)" srcSet={top_banner_background_mobile}/>
                    <img src={top_banner_background_mobile} alt="Flowers"/>
                </picture>

    </div>


    

</Slider>
            
            
            
       
    </section>
    


</main>
  );
}

export default NotFoundPage;
