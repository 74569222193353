import React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function ShopifyButton(props) {
  
  const scriptURL =
    "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

  useEffect(() => {
  /*   console.log("shopfyId", props.idShopify1);
    console.log("SHOPIFY", window.ShopifyBuy);
    console.log(window.ShopifyBuy.UI); */

   /* let examp = document.getElementById("product-component-1638393896072"); */
    

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
  }, []);

  function ShopifyBuyInit() {
    var client = window.ShopifyBuy.buildClient({
      domain: "the-urban-secrets.myshopify.com",
      storefrontAccessToken: "753d5fe3680e752c2b34cee31e7468e6",
    });

    window.ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent("product", {
        id: `${props.idShopify1}`,
        node: document.getElementById(`product-component-${props.idShopify2}`),
        moneyFormat: "%24%7B%7Bamount%7D%7D",
        options: {
          "product": {
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px"
                }
              },
              "button": {
                "font-family": "Arial, sans-serif",
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                ":hover": {
                  "background-color": "#d84ca6"
                },
                "background-color": "#f054b8",
                ":focus": {
                  "background-color": "#d84ca6"
                },
                "border-radius": "0px",
                "padding-left": "38px",
                "padding-right": "38px"
              },
              "quantityInput": {
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px"
              }
            },
            "contents": {
              "img": false,
              "title": false,
              "price": false
            }
          },
          "productSet": {
            "styles": {
              "products": {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px"
                }
              }
            }
          },
          "modalProduct": {
            "contents": {
              "img": false,
              "imgWithCarousel": true,
              "button": false,
              "buttonWithQuantity": true
            },
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px"
                }
              },
              "button": {
                "font-family": "Arial, sans-serif",
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                ":hover": {
                  "background-color": "#d84ca6"
                },
                "background-color": "#f054b8",
                ":focus": {
                  "background-color": "#d84ca6"
                },
                "border-radius": "0px",
                "padding-left": "38px",
                "padding-right": "38px"
              },
              "quantityInput": {
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px"
              }
            },
            "text": {
              "button": "Add to cart"
            }
          },
          "option": {},
          "cart": {
            "styles": {
              "button": {
                "font-family": "Arial, sans-serif",
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                ":hover": {
                  "background-color": "#d84ca6"
                },
                "background-color": "#f054b8",
                ":focus": {
                  "background-color": "#d84ca6"
                },
                "border-radius": "0px"
              }
            },
            "text": {
              "title": "YOUR SHOPPING CART",
              "empty": "YOUR CART IS EMPTY",
              "notice": "FREE SHIPPING IN USA AND CANADA!",
              "noteDescription": "ANY SPECIAL REQUESTS?"
            },
            "contents": {
              "note": true
            }
          },
          "'toggle'": {
            "styles": {
              "toggle": {
                "font-family": "Arial, sans-serif",
                "background-color": "#f054b8",
                "display":"block",
                ":hover": {
                  "background-color": "#d84ca6"
                },
                ":focus": {
                  "background-color": "#d84ca6"
                }
              },
              "count": {
                "font-size": "16px"
              }
            }
          }
        },
      });
    });
  }

  function loadScript() {
    var script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
  /*   document.getElementById(
         document.getElementsByTagName("h1")[0] || document.getElementsByTagName("body")[0]).appendChild(script); */
       /*  document.getElementsByTagName("h1")).appendChild(script) */
       document.getElementById(`product-component-${props.idShopify2}`).appendChild(script)
         script.onload = ShopifyBuyInit;
  } 
  

  return (
    <div>
      <div
        id={`product-component-${props.idShopify2}`}
        style={{ position: "relative" , width: 280, height: 80, margin: "auto" }}
      >
       
      </div>
    
    </div>
  );
}

export default ShopifyButton;
